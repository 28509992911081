


import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class Home extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;

  public receivedHeight: number = 300;

  public get iframeSrc(): string {
    let result = '/documents/about/organizers_en.html';

    switch (this.$route.name) {
      case 'home':
        result = '/documents/about/organizers_en.html';
        if (this.$i18n.locale === 'ru') {
          result = '/documents/about/organizers_ru.html';
        }
        break;
      case 'home-forexhibitors':
        result = '/documents/about/exhibitors_en.html';
        if (this.$i18n.locale === 'ru') {
          result = '/documents/about/exhibitors_ru.html';
        }
        break;
      default:
    }

    return result;
  }

  public get aboutIframeHeight(): string {
    return '100vh';
    // return this.receivedHeight === 0 ? '0' : this.receivedHeight.toFixed(0) + 'px';
  }

  public mounted(): void {
    this.attachPostMessageHandler();
    this.addExtraRootElementClasses();
    this.$router.push({ name: 'event-list-my' });
  }

  public beforeDestroy(): void {
    this.removePostMessageHandler();
    this.removeExtraRootElementClasses();
  }

  public addExtraRootElementClasses(): void {
    document.documentElement.classList.add('root-is-about-landing');
    document.documentElement.classList.add('root-no-persistent-scrollbars');
  }

  public removeExtraRootElementClasses(): void {
    document.documentElement.classList.remove('root-is-about-landing');
    document.documentElement.classList.remove('root-no-persistent-scrollbars');
  }

  public attachPostMessageHandler(): void {
    window.addEventListener('message', this.handlePostMessage, false);
  }

  public removePostMessageHandler(): void {
    window.removeEventListener('message', this.handlePostMessage);
  }

  public handlePostMessage(event: MessageEvent): void {
    if (!event || !event.data || !event.data.type) {
      return;
    }

    switch (event.data.type) {
      case 'ew-about-height':
        this.receivedHeight = event.data.height;
        break;
      case 'ew-about-change-lang':
        this.handleChangeLanguage(event.data.lang);
        break;
      case 'ew-about-change-route':
        this.handleChangeRoute(event.data.routeName);
        break;
      case 'ew-about-scroll-to':
        this.handleScrollCommand(event.data.coords);
        break;
      default:
        break;
    }
  }

  public handleChangeLanguage(lang: string): void {
    this.receivedHeight = 700;
    this.$router.push({ name: this.$route.name, params: { lang: lang } }).catch(() => { /* ignore */ });
  }

  public handleChangeRoute(routeName: string): void {
    this.receivedHeight = 700;
    this.$router.push({ name: routeName }).catch(() => { /* ignore */ });
  }

  public handleScrollCommand(coords: { x: number; y: number }): void {
    window.scrollTo(coords.x, coords.y);
  }

}
